.nav-icon {
  color: rgb(59, 59, 59);
  padding-left: 5px;
  padding-right: 5px;
}

.navbar {
   /* font-family: "Exo", sans-serif; */
  font-family: 'Audiowide', cursive; 
  position: fixed;
  top:0;
  width: 100%;
  border-bottom:#122d53dc solid 3px;
}