.attention-getter {
  color: white;
  /* font-family: "Exo", sans-serif; */
  font-family: "Audiowide", cursive;
  padding-bottom: 10px;
  border-bottom: rgba(255, 255, 255, 0.575) solid 2px;
}

#jumbotron {
  margin-top: 15%;
  color: white;
}

.btn-primary {
  margin-right: 1rem;
  background-color: #588eda;
  border:#588eda;
}

.btn-primary:hover{
  margin-right: 1rem;
  background-color: #588edada;
  border:#588eda;
}

.btn-primary:active {
  margin-right: 1rem;
  background-color: #588edada;
  border:#588eda;
}
.btn-primary:focus {
  margin-right: 1rem;
  background-color: #588edada;
  border:#588eda;
}

.btn-light {
  margin-left: 1rem;
}
.btn-light:hover {
  margin-left: 1rem;
  background-color: rgba(245, 245, 245, 0.89);
}
