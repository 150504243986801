#home-page {
  background: url(../../assets/img/background.png) no-repeat center center fixed;
  
  /* height: 100%; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: visible;
  

}