.footer {
  /* position: fixed;
  bottom: 0;
  left: 0;
  flex: none; */


  text-align: center;
  color: #d0d0d1;
  background-color: #343a40dc;
  font-size: 16px;
  line-height: 3;
  border-top: #122d53dc solid 3px;
  width: 100%;

  height: 60px;
  margin-top: -60px;
}
