h2 {
  font-family: "Exo", sans-serif;
  /* font-family: 'Audiowide', cursive; */
  padding-bottom: 10px;
 text-decoration: underline;
}

.skills-icon{
  color:#122d53dc
}

.skills-icon:hover{
  color:#588EDA;
}